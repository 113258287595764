/**
 * README
 *
 * Meta properties:
 * - requiresAuth (boolean): visitor should be logged in
 * - requiresUnauth (boolean): ???
 * - isDpOnly (boolean): visitor should be logged in and should be a DP user
 * - requiresFeature (string): visitor should be logged in and has access to the given feature
 * - requiresLicense (string): visitor should be logged in and has the given license assigned
 */
import {createRouter, createWebHistory} from 'vue-router';
import {AuthContext} from "@/context/AuthContext";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";

import Oauth2Callback from '../pages/auth/Oauth2Callback.vue';
import Home from '../pages/Home.vue';
import LoginPage from '../pages/auth/LoginPage.vue';
import NotFound from '../pages/NotFound.vue';
import FileUploadDemo from '../pages/FileUploadDemo.vue';
import store from '../store/index.js';
import ComingSoon from "@/pages/ComingSoon";
import abilities from '@/config/ability';
import DashboardHomepage from "@/pages/DashboardHomepage.vue";

import IndexHomepage from "@/pages/homepage/Index";
import Logo from "@/pages/homepage/Logo";
import StickyLogo from "@/pages/homepage/StickyLogo.vue";
import MainImage from "@/pages/homepage/MainImage";
import WelcomeText from "@/pages/homepage/WelcomeText";
import GeneralMessage from "@/pages/homepage/GeneralMessage";
import HomepageContent from "@/pages/homepage/HomepageContent";
import StandardHomepageContentBlocksOverview from "@/pages/homepage/StandardHomepageContentBlocksOverview";
import StandardHomepageContentBlocksDetail from "@/pages/homepage/StandardHomepageContentBlocksDetail";
import AboutUs from "@/pages/homepage/AboutUs";
import BasicHomepageMeta from "@/pages/homepage/BasicHomepageMeta";
import TeamMembersOverview from "@/pages/homepage/TeamMembersOverview";
import TeamMembersDetail from "@/pages/homepage/TeamMembersDetail";
import PharmacyOnCall from "@/pages/homepage/PharmacyOnCall";
import ReimburesedCareRatesDoc from "@/pages/homepage/ReimburesedCareRatesDoc.vue";

import IndexContentPages from "@/pages/content-pages/Index.vue";
import ContentPagesDetail from "@/pages/content-pages/ContentPagesDetail.vue";
import ContentPagesDetailWysiwyg from "@/pages/content-pages/ContentPagesDetailWysiwyg.vue";
import ContentPagesOverview from "@/pages/content-pages/ContentPagesOverview";

import HeaderOverview from "@/pages/header-footer/HeaderOverview.vue";
import HeaderDetail from "@/pages/header-footer/HeaderDetail.vue";
import FooterDetail from "@/pages/header-footer/FooterDetail.vue";
import FooterOverview from "@/pages/header-footer/FooterOverview.vue";

import IndexMenus from "@/pages/menus/Index";
import MenuOverview from "@/pages/menus/MenuOverview.vue";
import MenuDetail from "@/pages/menus/MenuDetail.vue";
import CreateMenu from "@/pages/menus/CreateMenu.vue";
import MenuItemsDetail from "@/pages/menus/MenuItemsDetail";

import IndexBanners from "@/pages/banners/Index";
import BannersContentProviderIndex from "@/pages/banners/content-provider/Index";
import BannersContentProviderBannerSlotDetail from "@/pages/banners/content-provider/BannerSlotDetail";
import BannersContentProviderBannerSlotOverview from "@/pages/banners/content-provider/BannerSlotOverview";
import BannersCustomerIndex from "@/pages/banners/customer/Index";
import BannersCustomerBannerDetail from "@/pages/banners/customer/BannerDetail";
import BannersCustomerBannerOverview from "@/pages/banners/customer/BannerOverview";
import BannersCustomerBannerSlotDetail from "@/pages/banners/customer/BannerSlotDetail";
import BannersCustomerBannerSlotOverview from "@/pages/banners/customer/BannerSlotOverview";

import IndexPharmacy from "@/pages/pharmacy/Index.vue";
import PharmacyInfo from "@/pages/pharmacy/PharmacyInfo.vue";
import PharmacyLandingPage from "@/pages/pharmacy/PharmacyLandingPage.vue";

import IndexReservationCampaigns from "@/pages/reservation-campaigns/Index";
import ReservationCampaignsDetail from "@/pages/reservation-campaigns/ReservationCampaignsDetail";
import ReservationCampaignsOverview from "@/pages/reservation-campaigns/ReservationCampaignsOverview";

import IndexCatalog from "@/pages/catalog/Index";
import CatalogProductsOverview from "@/pages/catalog/ProductsOverview.vue";
import CatalogProductDetail from "@/pages/catalog/CatalogProductDetail.vue";
import BrandOrder from "@/pages/catalog/BrandOrder";
import CatalogTitle from "@/pages/catalog/CatalogTitle";
import CategoriesContentBlock from "@/pages/catalog/CategoriesContentBlock";
import CustomerProductsOverview from "@/pages/catalog/CustomerProductsOverview";
import CustomerProductsDetail from "@/pages/catalog/CustomerProductsDetail";
import DiscountsPriceLists from "@/pages/catalog/DiscountsPriceLists";
import DiscountsDetail from "@/pages/catalog/DiscountsDetail";
import PriceListsDetail from "@/pages/catalog/PriceListsDetail";
import ProductFiltersDetail from "@/pages/catalog/ProductFiltersDetail";
import ProductFiltersOverview from "@/pages/catalog/ProductFiltersOverview";
import ProductSelection from "@/pages/catalog/ProductSelection";
import ProductsInTheSpotlight from "@/pages/catalog/ProductsInTheSpotlight";
import StarterDiscounts from "@/pages/catalog/StarterDiscounts";
import StarterProductSelection from "@/pages/catalog/StarterProductSelection";
import OrderLabelOverview from "@/pages/catalog/OrderLabelOverview";
import OrderLabelDetail from "@/pages/catalog/OrderLabelDetail";

import IndexFallbackContent from "@/pages/fallback-content/Index";

import IndexOpeningHours from "@/pages/openinghours/Index";
import ClosingPeriodsOverview from "@/pages/openinghours/ClosingPeriodsOverview";
import ClosingPeriodsDetail from "@/pages/openinghours/ClosingPeriodsDetail";
import StandardOpeningHours from "@/pages/openinghours/StandardOpeningHours";
import OpeningHoursPeriodsOverview from "@/pages/openinghours/OpeningHoursPeriodsOverview";
import OpeningHoursPeriodsDetail from "@/pages/openinghours/OpeningHoursPeriodsDetail";

import IndexLibraries from "@/pages/libraries/Index";
import LibrariesAvailable from "@/pages/libraries/LibrariesAvailable.vue";
import LibraryDetail from "@/pages/libraries/LibraryDetail.vue";
import LibraryOverview from "@/pages/libraries/LibraryOverview.vue";

import IndexContentPagesLibraries from "@/pages/libraries/content-pages/Index";
import ContentPageLibraryOverview from "@/pages/libraries/content-pages/ContentPageLibraryOverview";
import ContentPageLibraryDetail from "@/pages/libraries/content-pages/ContentPageLibraryDetail.vue";
import ContentPageLibraryDetailWysiwyg from "@/pages/libraries/content-pages/ContentPageLibraryDetailWysiwyg";

import IndexSettings from "@/pages/settings/Index";
import Language from "@/pages/settings/Language";
import ColorScheme from "@/pages/settings/ColorScheme";
import GtmSettings from "@/pages/settings/GtmSettings.vue";
import CustomCss from "@/pages/settings/CustomCss.vue";
import CustomJs from "@/pages/settings/CustomJs.vue";
import Emails from "@/pages/settings/Emails";
import PharmacollectHomepage from "@/pages/settings/PharmacollectHomepage";
import PharmacyGroup from "@/pages/settings/PharmacyGroup.vue";
import PharmaPayOverview from "@/pages/settings/PharmaPayOverview.vue";
import DeliveryMethods from "@/pages/settings/DeliveryMethods.vue";
import DeliveryCostRuleOverview from "@/pages/catalog/DeliveryCostRuleOverview.vue";
import DeliveryCostRuleDetail from "@/pages/catalog/DeliveryCostRuleDetail.vue";
import DeliveryMethodTextOverview from "@/pages/catalog/DeliveryMethodTextOverview.vue";
import DeliveryMethodTextDetail from "@/pages/catalog/DeliveryMethodTextDetail.vue";

import IndexPim from "@/pages/pim/Index.vue";
import PimProductsOverview from "@/pages/pim/PimProductsOverview.vue";
import PimLabProductsOverview from "@/pages/pim/PimLabProductsOverview.vue";
import PimExport from "@/pages/pim/PimExport.vue";

import OptionPackOverview from "@/pages/licensing/OptionPackOverview.vue";
import Support from "@/pages/Support";
import AllPharmacies from "@/pages/AllPharmacies";
import Oauth2V2Callback from "@/pages/auth/Oauth2V2Callback.vue";
import DebugSupport from "@/pages/DebugSupport.vue";
import Password from "@/pages/profile/Password.vue";
import Training from "@/pages/Training.vue";
import NamedPageContent from "@/pages/content-pages/NamedPageContent.vue";
import PrivacyPolicy from "@/pages/homepage/PrivacyPolicy.vue";
import GeneralTerms from "@/pages/homepage/GeneralTerms.vue";
import PharmaShipping from "@/pages/settings/PharmaShipping.vue";
import Sendcloud from "@/pages/settings/pharma-shipping/Sendcloud.vue";
import ShoppingCartDiscountsDetail from "@/pages/catalog/ShoppingCartDiscountsDetail.vue";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', redirect: '/home'},
        {name: 'home', path: '/home', components: {default: Home}},
        {name: 'login', path: '/login', components: {default: LoginPage}},
        {name: 'demo', path: '/demo', components: {default: FileUploadDemo}},
        {path: '/auth', component: LoginPage, meta: {requiresUnauth: true}},
        {path: '/oauth2/callback', component: Oauth2Callback, meta: {requiresUnauth: false}},
        {path: '/oauth2/v2/callback', component: Oauth2V2Callback, meta: {requiresUnauth: false}},
        {path: '/:notFound(.*)', component: NotFound},
        {
            name: 'pharmacies',
            path: '/pharmacies',
            meta: {requiresAuth: true},
            component: AllPharmacies,
        },
        {
            name: 'coming-soon',
            path: '/coming-soon',
            meta: {requiresAuth: true},
            component: ComingSoon,
        },
        {
            name: 'licensing-option-pack',
            path: '/licensing-option-pack',
            meta: {requiresAuth: true},
            component: OptionPackOverview,
        },
        {
            name: 'support',
            path: '/support',
            meta: {requiresAuth: true},
            component: Support,
        },
        {
            name: 'support-debug',
            path: '/support/debug',
            meta: {
                requiresAuth: true,
                isDpOnly: true,
            },
            component: DebugSupport,
        },
        {
            name: 'training',
            path: '/training',
            meta: {
                requiresAuth: true,
                isDpOnly: false,
            },
            component: Training,
        },
        {
            name: 'dashboard',
            path: '/dashboard',
            meta: {
                requiresAuth: true,
            },
            component: DashboardHomepage,
        },
        {
            name: 'profile-password',
            path: '/profile/password',
            meta: {
                requiresAuth: true,
            },
            component: Password,
        },
        {
            name: 'homepage',
            path: '/homepage',
            component: IndexHomepage,
            children: [
                {
                    name: 'logo',
                    path: 'logo',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT'
                    },
                    component: Logo
                },
                {
                    name: 'sticky-logo',
                    path: 'sticky-logo',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'STICKY_LOGO'
                    },
                    component: StickyLogo
                },
                {
                    name: 'header-photo',
                    path: 'header-photo',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: MainImage
                },
                {
                    name: 'welcome-text',
                    path: 'welcome-text',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: WelcomeText
                },
                {
                    name: 'general-message',
                    path: 'general-message',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: GeneralMessage
                },
                {
                    name: 'edit-homepage',
                    path: 'content',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'HOMEPAGE_CONTENT_PAGE'
                    },
                    component: HomepageContent,
                },
                {
                    name: 'standard-homepage-content-blocks',
                    path: 'standard-content-blocks',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: StandardHomepageContentBlocksOverview
                },
                {
                    name: 'edit-standard-homepage-content-block',
                    path: 'standard-content-block/:id',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: StandardHomepageContentBlocksDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-standard-homepage-content-block',
                    path: 'standard-content-block',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'BASIC_HOMEPAGE_CONTENTBLOCKS'
                    },
                    component: StandardHomepageContentBlocksDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'about-us',
                    path: 'about-us',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT' && 'ABOUT_US'
                    },
                    component: AboutUs
                },
                {
                    name: 'team-members',
                    path: 'team-members',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT'
                    },
                    component: TeamMembersOverview
                },
                {
                    name: 'edit-team-member',
                    path: 'team-member/:id',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT'
                    },
                    component: TeamMembersDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-team-member',
                    path: 'team-member',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'MGMT_CUSTOMER_WEBSITE_CONTENT'
                    },
                    component: TeamMembersDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'pharmacy-on-call',
                    path: 'pharmacy-on-call',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'PHARMACY_ON_CALL_URL'
                    },
                    component: PharmacyOnCall
                },
                {
                    name: 'reimburesed-care-rates',
                    path: 'reimburesed-care-rates',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'REIMBURSED_CARE_RATES_DOCUMENT'
                    },
                    component: ReimburesedCareRatesDoc
                },
                {
                    name: 'basic-home-page-meta',
                    path: 'basic-home-page-meta',
                    component: BasicHomepageMeta,
                    meta: {
                        requiresFeature: 'BASIC_HOMEPAGE_META_ABSTRACT'
                    },
                },
                {
                    name: 'general-terms',
                    path: 'general-terms',
                    component: GeneralTerms,
                    meta: {
                        requiresFeature: 'GENERAL_TERMS'
                    },
                },
                {
                    name: 'privacy-policy',
                    path: 'privacy-policy',
                    component: PrivacyPolicy,
                    meta: {
                        requiresFeature: 'PRIVACY_POLICY'
                    },
                },
            ]
        },
        {
            name: 'content-pages',
            path: '/content-pages',
            component: ContentPagesOverview,
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_CONTENT_PAGE'
            },
        },
        {
            name: 'content-page',
            path: '/content-page',
            component: IndexContentPages,
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_CONTENT_PAGE'
            },
            children: [
                {
                    name: 'new-content-page',
                    path: '',
                    component: ContentPagesDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'edit-content-page-wysiwyg',
                    path: 'wysiwyg/:id',
                    component: ContentPagesDetailWysiwyg,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-content-page-wysiwyg',
                    path: 'wysiwyg',
                    component: ContentPagesDetailWysiwyg,
                    props: {mode: 'new'},
                },
            ]
        },
        {
            name: 'edit-named-page',
            path: '/named-page/:code',
            meta: {
                requiresAuth: true,
                requiresFeature: 'WEBSHOP_INFO_PAGES'
            },
            component: NamedPageContent,
        },
        //todo activate when multiple menus possibles
        // {
        //     name: 'menus',
        //     path: '/menus',
        //     component: ,
        //     meta: {
        //         requiresAuth: true,
        //         requiresFeature: 'MGMT_WEBSITE_MENU'
        //     },
        // },
        {
            name: 'menu',
            path: '/menu',
            component: IndexMenus,
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_WEBSITE_MENU'
            },
            children: [
                {
                    name: 'menus',
                    path: 'menus',
                    component: MenuOverview,
                },
                {
                    name: 'edit-menu',
                    path: ':menu_id',
                    component: MenuDetail,
                    props: {mode: PageDetailModeEnum.EDIT},
                },
                {
                    name: 'new-menu',
                    path: '',
                    component: CreateMenu,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-menu-item',
                    path: ':menu_id/item/:menu_item_id',
                    component: MenuItemsDetail,
                    props: {mode: PageDetailModeEnum.EDIT, isSubMenu: false},
                },
                {
                    name: 'new-menu-item',
                    path: ':menu_id/item',
                    component: MenuItemsDetail,
                    props: {mode: PageDetailModeEnum.NEW, isSubMenu: false},
                },
                {
                    name: 'edit-sub-menu-item',
                    path: ':menu_id/sub-item/:menu_item_id',
                    component: MenuItemsDetail,
                    props: {mode: PageDetailModeEnum.EDIT, isSubMenu: true},
                },
                {
                    name: 'new-sub-menu-item',
                    path: ':menu_id/sub-item',
                    component: MenuItemsDetail,
                    props: {mode: PageDetailModeEnum.NEW, isSubMenu: true},
                },
            ]
        },
        {
            name: 'banners',
            path: '/banners',
            component: IndexBanners,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    name: 'content-provider-banners',
                    path: 'content-provider',
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_LIB_CONTENT'
                    },
                    component: BannersContentProviderIndex,
                    children: [
                        {
                            name: 'content-provider-banner-slots',
                            path: 'banner-slots',
                            component: BannersContentProviderBannerSlotOverview,
                        },
                        {
                            name: 'content-provider-banner-slot-edit',
                            path: 'banner-slot/:id',
                            component: BannersContentProviderBannerSlotDetail,
                            props: {mode: 'edit'},
                        },
                    ]
                },
                {
                    name: 'customer-banners',
                    path: 'customer',
                    meta: {
                        requiresFeature: 'BANNERS'
                    },
                    component: BannersCustomerIndex,
                    children: [
                        {
                            name: 'customer-banner-slots',
                            path: 'banner-slots',
                            component: BannersCustomerBannerSlotOverview,
                        },
                        {
                            name: 'customer-banner-slot-edit',
                            path: 'banner-slot/:id',
                            component: BannersCustomerBannerSlotDetail,
                            props: {mode: 'edit'},
                        },
                        {
                            name: 'customer-banners-overview',
                            path: 'banners',
                            meta: {
                                requiresAuth: true,
                                requiresFeature: 'CREATE_CUSTOM_BANNERS'
                            },
                            component: BannersCustomerBannerOverview,
                        },
                        {
                            name: 'customer-banners-new',
                            path: 'banner',
                            meta: {
                                requiresAuth: true,
                                requiresFeature: 'CREATE_CUSTOM_BANNERS'
                            },
                            component: BannersCustomerBannerDetail,
                            props: {mode: 'new'},
                        },
                        {
                            name: 'customer-banners-edit',
                            path: 'banner/:id',
                            meta: {
                                requiresAuth: true,
                                requiresFeature: 'CREATE_CUSTOM_BANNERS'
                            },
                            component: BannersCustomerBannerDetail,
                            props: {mode: 'edit'},
                        },
                    ]
                },
            ]
        },
        {
            name: 'headers',
            path: '/headers',
            component: HeaderOverview,
            meta: {
                requiresAuth: true,
                requiresFeature: 'CUSTOM_HEADER_STACK'
            }
        },
        {
            name: 'edit-header',
            path: '/header/:type/:id',
            component: HeaderDetail,
            meta: {
                requiresAuth: true,
                requiresFeature: 'CUSTOM_HEADER_STACK'
            }
        },
        {
            name: 'footers',
            path: '/footers',
            component: FooterOverview,
            meta: {
                requiresAuth: true,
                requiresFeature: 'CUSTOM_FOOTER_STACK'
            }
        },
        {
            name: 'edit-footer',
            path: '/footer/:type/:id',
            component: FooterDetail,
            meta: {
                requiresAuth: true,
                requiresFeature: 'CUSTOM_FOOTER_STACK'
            }
        },
        {
            name: 'opening-hours',
            path: '/opening-hours',
            meta: {
                requiresAuth: true,
                requiresFeature: 'OPENING_HOURS'
            },
            component: IndexOpeningHours,
            children: [
                {
                    name: 'standard-hours',
                    path: 'standard-hours',
                    component: StandardOpeningHours,
                },
                {
                    name: 'exceptional-hours',
                    path: 'exceptional-hours',
                    component: OpeningHoursPeriodsOverview,
                },
                {
                    name: 'edit-exceptional-hour',
                    path: 'exceptional-hour/:id',
                    component: OpeningHoursPeriodsDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-exceptional-hour',
                    path: 'exceptional-hour',
                    component: OpeningHoursPeriodsDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'closing-periods',
                    path: 'closing-periods',
                    component: ClosingPeriodsOverview
                },
                {
                    name: 'edit-closing-period',
                    path: 'closing-period/:id',
                    component: ClosingPeriodsDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-closing-period',
                    path: 'closing-period',
                    component: ClosingPeriodsDetail,
                    props: {mode: 'new'},
                },
            ]
        },
        {
            name: 'pharmacy',
            path: '/pharmacy',
            meta: {
                requiresAuth: true,
            },
            component: IndexPharmacy,
            children: [
                {
                    name: 'pharmacy-info',
                    path: 'info',
                    meta: {
                        requiresFeature: 'MGMT_PHARMACY_DATA'
                    },
                    component: PharmacyInfo,
                },
                {
                    name: 'pharmacy-landing-page',
                    path: 'landing-page',
                    meta: {
                        requiresFeature: 'PHARMACY_LANDING_PAGE'
                    },
                    component: PharmacyLandingPage,
                },
            ]
        },
        {
            name: 'reservation-campaigns',
            path: '/reservation-campaigns',
            component: ReservationCampaignsOverview,
            meta: {
                requiresAuth: true,
                requiresFeature: 'ORCA'
            },
        },
        {
            name: 'reservation-campaign',
            path: '/reservation-campaign',
            component: IndexReservationCampaigns,
            meta: {
                requiresAuth: true,
                requiresFeature: 'ORCA'
            },
            children: [
                {
                    name: 'edit-reservation-campaign',
                    path: ':id',
                    component: ReservationCampaignsDetail,
                    props: {mode: 'edit'},
                },
                //TODO enable when creating is possible
                // {
                //     name: 'new-reservation-campaign',
                //     path: '',
                //     component: ReservationCampaignsDetail,
                //     props: {mode: 'new'},
                // },
            ]
        },
        {
            name: 'catalog',
            path: '/catalog',
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_CUSTOMER_CATALOG_SETTINGS'
            },
            component: IndexCatalog,
            children: [
                {
                    name: 'catalog-products-overview',
                    path: 'products',
                    component: CatalogProductsOverview,
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_CATALOG_PRODUCTS'
                    },
                },
                {
                    name: 'catalog-products-detail',
                    path: 'product/:product_code',
                    component: CatalogProductDetail,
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_CATALOG_PRODUCTS'
                    },
                },
                {
                    name: 'catalog-title',
                    path: 'title',
                    component: CatalogTitle,
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_CATALOG_CONTENT'
                    },
                },
                {
                    name: 'categories-content-block',
                    path: 'categories-content-block',
                    component: CategoriesContentBlock,
                    meta: {
                        requiresFeature: 'CATEGORIES_CONTENT_BLOCK'
                    },
                },
                {
                    name: 'customer-products',
                    path: 'customer-products',
                    component: CustomerProductsOverview,
                    meta: {
                        requiresFeature: 'CUSTOMER_PRODUCT'
                    },
                },
                {
                    name: 'edit-customer-product',
                    path: 'customer-product/:id',
                    component: CustomerProductsDetail,
                    props: {mode: 'edit'},
                    meta: {
                        requiresFeature: 'CUSTOMER_PRODUCT'
                    },
                },
                {
                    name: 'new-customer-product',
                    path: 'customer-product',
                    component: CustomerProductsDetail,
                    props: {mode: 'new'},
                    meta: {
                        requiresFeature: 'CUSTOMER_PRODUCT'
                    },
                },
                {
                    name: 'discounts-price-lists',
                    path: 'discounts-price-lists',
                    component: DiscountsPriceLists,
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'edit-discount',
                    path: 'discount/:id',
                    component: DiscountsDetail,
                    props: {mode: 'edit'},
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'new-discount',
                    path: 'discount',
                    component: DiscountsDetail,
                    props: {mode: 'new'},
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'edit-shopping-cart-discount',
                    path: 'shopping-cart-discount/:id',
                    component: ShoppingCartDiscountsDetail,
                    props: {mode: 'edit'},
                    meta: {
                        requiresFeature: 'ORDER_BASED_DISCOUNTS'
                    },
                },
                {
                    name: 'new-shopping-cart-discount',
                    path: 'shopping-cart-discount',
                    component: ShoppingCartDiscountsDetail,
                    props: {mode: 'new'},
                    meta: {
                        requiresFeature: 'ORDER_BASED_DISCOUNTS'
                    },
                },
                {
                    name: 'edit-price-list',
                    path: 'price-list/:id',
                    component: PriceListsDetail,
                    props: {mode: 'edit'},
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'new-price-list',
                    path: 'price-list',
                    component: PriceListsDetail,
                    props: {mode: 'new'},
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'product-filters',
                    path: 'product-filters',
                    component: ProductFiltersOverview,
                    meta: {
                        requiresFeature: 'PREDEFINED_PRODUCT_FILTERS_CONDITIONS'
                    },
                },
                {
                    name: 'edit-product-filter',
                    path: 'product-filter/:code',
                    component: ProductFiltersDetail,
                    props: {mode: 'edit'},
                    meta: {
                        requiresFeature: 'PREDEFINED_PRODUCT_FILTERS_CONDITIONS'
                    },
                },
                {
                    name: 'new-product-filter',
                    path: 'product-filter',
                    component: ProductFiltersDetail,
                    props: {mode: 'new'},
                    meta: {
                        requiresFeature: 'NAMED_PRODUCT_FILTER'
                    },
                },
                {
                    name: 'brand-order',
                    path: 'brand-order',
                    component: BrandOrder,
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'product-selection',
                    path: 'product-selection',
                    component: ProductSelection,
                    meta: {
                        requiresFeature: 'CUSTOMER_CATALOG'
                    },
                },
                {
                    name: 'products-spotlight',
                    path: 'products-spotlight',
                    component: ProductsInTheSpotlight,
                    meta: {
                        requiresFeature: 'PRODUCTS_IN_THE_SPOTLIGHT'
                    },
                },
                {
                    name: 'starter-discounts',
                    path: 'starter-discounts',
                    component: StarterDiscounts,
                    meta: {
                        requiresFeature: 'STARTER_PRODUCT_DISCOUNT'
                    },
                },
                {
                    name: 'starter-product-selection',
                    path: 'starter-product-selection',
                    component: StarterProductSelection,
                    meta: {
                        requiresFeature: 'STARTER_PRODUCT_BRAND_SELECTION'
                    },
                },
                {
                    name: 'delivery-method-texts',
                    path: 'delivery-method-texts',
                    component: DeliveryMethodTextOverview,
                    meta: {
                        requiresFeature: 'ALTERNATIVE_DELIVERY_METHODS'
                    },
                },
                {
                    name: 'edit-delivery-method-text',
                    path: 'delivery-method-texts/:delivery_method_code/:type_code',
                    component: DeliveryMethodTextDetail,
                    meta: {
                        requiresFeature: 'ALTERNATIVE_DELIVERY_METHODS'
                    },
                },
                {
                    name: 'delivery-cost-rules',
                    path: 'delivery-cost-rules',
                    component: DeliveryCostRuleOverview,
                    meta: {
                        requiresFeature: 'ALTERNATIVE_DELIVERY_METHODS_SHIPPING'
                    },
                },
                {
                    name: 'delivery-cost-rule-edit',
                    path: 'delivery-cost-rules/edit/:id',
                    component: DeliveryCostRuleDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'delivery-cost-rule-new',
                    path: 'delivery-cost-rules/new',
                    component: DeliveryCostRuleDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'order-label-content-blocks',
                    path: 'order-labels',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'ORDER_LABELS_ASSIGNED_BY_DOCTOR'
                    },
                    component: OrderLabelOverview
                },
                {
                    name: 'edit-order-label-content-block',
                    path: 'order-label/:id',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'ORDER_LABELS_ASSIGNED_BY_DOCTOR'
                    },
                    component: OrderLabelDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'new-order-label-content-block',
                    path: 'order-label',
                    meta: {
                        requiresAuth: true,
                        requiresFeature: 'ORDER_LABELS_ASSIGNED_BY_DOCTOR'
                    },
                    component: OrderLabelDetail,
                    props: {mode: 'new'},
                },
            ]
        },
        {
            name: 'fallback-content',
            path: '/fallback',
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_FALLBACK_CONTENT'
            },
            component: IndexFallbackContent,
            children: [
                {
                    name: 'delivery-method-fallback-texts',
                    path: 'delivery-method-fallback-texts',
                    component: DeliveryMethodTextOverview,
                },
                {
                    name: 'edit-delivery-method-fallback-text',
                    path: 'delivery-method-fallback-texts/:delivery_method_code/:type_code',
                    component: DeliveryMethodTextDetail,
                },
            ]
        },
        {
            name: 'libraries',
            path: '/libraries',
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_CUSTOMER_LIB_CONTENT'
            },
            component: IndexLibraries,
            children: [
                {
                    name: 'libraries-available',
                    path: 'available/:cb_structure',
                    component: LibrariesAvailable,
                },
                {
                    name: 'library-overview',
                    path: ':cb_structure/:cb_type/:lib_type',
                    component: LibraryOverview,
                },
                {
                    name: 'library-edit',
                    path: ':cb_structure/:cb_type/:lib_type/edit/:id',
                    component: LibraryDetail,
                    props: {mode: 'edit'},
                },
                {
                    name: 'library-new',
                    path: ':cb_structure/:cb_type/:lib_type/new',
                    component: LibraryDetail,
                    props: {mode: 'new'},
                },
                {
                    name: 'libraries-content-pages',
                    path: 'content-pages',
                    component: IndexContentPagesLibraries,
                    children: [
                        {
                            name: 'libraries-content-pages-overview',
                            path: ':page_type_code/:lib_code',
                            component: ContentPageLibraryOverview,
                        },
                        {
                            name: 'libraries-new-content-page',
                            path: ':page_type_code/:lib_code/content-page',
                            component: ContentPageLibraryDetail,
                            props: {mode: 'new'},
                        },
                        {
                            name: 'libraries-edit-content-page-wysiwyg',
                            path: ':page_type_code/:lib_code/content-page/:id',
                            component: ContentPageLibraryDetailWysiwyg,
                            props: {mode: 'edit'},
                        }
                    ]
                },
            ]
        },
        {
            name: 'pharmacollect',
            path: '/pharmacollect',
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_PHARMACY_LOGO'
            },
            component: IndexSettings,
            children: [
                {
                    name: 'pharmacollect-logo',
                    path: 'logo',
                    component: Logo
                },
                {
                    name: 'pharmacollect-homepage',
                    path: 'homepage',
                    component: PharmacollectHomepage,
                    meta: {
                        requiresFeature: 'LINK_TO_HOMEPAGE'
                    },
                },
            ]
        },
        {
            name: 'pim',
            path: '/pim',
            component: IndexPim,
            meta: {
                requiresAuth: true,
                requiresFeature: 'PIM_PRODUCT_INFO_OVERVIEW' || 'PIM_PRODUCT_INFO' || 'PIM_LABO_PRODUCT_INFO' || 'PIM_PRODUCT_CATEGORY',
                // todo multiple license options does not work
                // requiresLicense: 'CP_PHARMA_PIM' || 'CP_PRODUCT_CATEGORY' || 'CP_LABO'
            },
            children: [
                {
                    name: 'pim-products-overview',
                    path: 'products',
                    component: PimProductsOverview,
                    meta: {
                        requiresFeature: 'PIM_PRODUCT_INFO_OVERVIEW'
                    },
                },
                {
                    name: 'pim-lab-products-overview',
                    path: 'products-lab',
                    component: PimLabProductsOverview,
                    meta: {
                        requiresLicense: 'CP_LABO',
                        requiresFeature: 'PIM_PRODUCT_INFO_OVERVIEW'
                    },
                },
                {
                    name: 'pim-export',
                    path: 'export',
                    component: PimExport,
                    meta: {
                        requiresFeature: 'PIM_PRODUCT_CATEGORY'
                    },
                }
            ]
        },
        {
            name: 'settings',
            path: '/settings',
            meta: {
                requiresAuth: true,
                requiresFeature: 'MGMT_CUSTOMER_SETTINGS'
            },
            component: IndexSettings,
            children: [
                {
                    name: 'language',
                    path: 'language',
                    component: Language,
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_SETTINGS_LANGUAGE'
                    },
                },
                {
                    name: 'color-scheme',
                    path: 'color-scheme',
                    component: ColorScheme,
                    meta: {
                        requiresFeature: 'COLOR_SCHEME'
                    },
                },
                {
                    name: 'gtm-settings',
                    path: 'gtm-settings',
                    component: GtmSettings,
                    meta: {
                        requiresFeature: 'GOOGLE_TAG_MANAGER'
                    },
                },
                {
                    name: 'custom-css',
                    path: 'custom-css',
                    component: CustomCss,
                    meta: {
                        requiresFeature: 'CUSTOM_CSS'
                    },
                },
                {
                    name: 'custom-js',
                    path: 'custom-js',
                    component: CustomJs,
                    meta: {
                        requiresFeature: 'CUSTOM_JS'
                    },
                },
                {
                    name: 'emails',
                    path: 'emails',
                    component: Emails,
                    meta: {
                        requiresFeature: 'MGMT_CUSTOMER_SETTINGS_EMAIL'
                    },
                },
                {
                    name: 'pharmacy-group',
                    path: 'pharmacy-group',
                    component: PharmacyGroup,
                    meta: {
                        requiresFeature: 'MGMT_PHARMACY_GROUP_INFO'
                    },
                },
                {
                    name: 'pharma-pay',
                    path: 'pharma-pay',
                    component: PharmaPayOverview,
                    meta: {
                        requiresFeature: 'PAYMENT_TRANSACTION'
                    },
                },
                {
                    name: 'pharma-shipping',
                    path: 'pharma-shipping',
                    component: PharmaShipping,
                    meta: {
                        requiresFeature: 'SHIPPING_SOFTWARE_INTEGRATION'
                    },
                },
                {
                    name: 'sendcloud',
                    path: 'pharma-shipping/sendcloud',
                    component: Sendcloud,
                    meta: {
                        requiresFeature: 'SENDCLOUD_INTEGRATION'
                    },
                },
                {
                    name: 'delivery-methods',
                    path: 'delivery-methods',
                    component: DeliveryMethods,
                    meta: {
                        requiresFeature: 'ALTERNATIVE_DELIVERY_METHODS'
                    },
                },
            ]
        },
    ],
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {left: 0, top: -1000};
    },
});

router.beforeEach(function (to, _, next) {
    if (to.meta.isDpOnly && !AuthContext.isDpUser()) {
        return next('/');
    }

    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        return next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        return next('/customers');
    } else {
        if (to.meta.requiresFeature && !abilities.can('use-feature', to.meta.requiresFeature)) {
            return next('/')
        }
        if (to.meta.requiresLicense && !abilities.can('use-license', to.meta.requiresLicense)) {
            return next('/')
        }
        return next();
    }
});

export default router;

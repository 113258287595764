
import {defineComponent} from "vue";
import MenusOverview from "@/components/layouts/menus/MenusOverview.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {TinyEmitter} from "tiny-emitter";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {FullMenuRestDto} from "@/api/pharma-cms-menu/models";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import {MenuLayoutEnum} from "@/api/enums/menu-layout-enum";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {AbilityContext} from "@/context/AbilityContext";
import {AuthContext} from "@/context/AuthContext";
import {MenuHorizontalPositionEnum} from "@/api/enums/menu-horizontal-position-enum";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";

export default defineComponent({
  name: "MenuDetail",
  components: {InputCheckboxBoolean, InputSelect, BaseModal, AlertError2, BaseTitle, MenusOverview},
  props: {
    mode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      bus: new TinyEmitter(),
      modalMenuSettings: null as Modal | null,
      modalMenuSettingsId: 'modal-menu-settings' as string,
      menuId: this.$route.params.menu_id as string,

      fullMenu: {} as FullMenuRestDto,
      fullMenuUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.modalMenuSettings = new Modal('#' + this.modalMenuSettingsId);

    this.bus.on('clearExceptions', this.clearExceptions);
    this.bus.on('reload', this.reloadContent);
    this.bus.on('setExceptions', this.setExceptions);
    this.bus.on('setIsReady', this.setIsReady);

    this.reloadContent();
  },
  unmounted() {
    this.modalMenuSettings?.dispose();
  },
  computed: {
    AuthContext() {
      return AuthContext
    },
    AbilityContext() {
      return AbilityContext
    },
    menuLayoutOptions(): CodeDescriptionRestDtoModel[] {
      // Todo, enums ophalen via api als Frits klaar is
      return createCodeDescriptionArrayFromEnum(MenuLayoutEnum);
    },
    menuHorizontalPositionOptions(): CodeDescriptionRestDtoModel[] {
      // Todo, enums ophalen via api als Frits klaar is
      return createCodeDescriptionArrayFromEnum(MenuHorizontalPositionEnum);
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.fullMenuUI.setNotReady();

      try {
        this.fullMenu = await MenuRestService.getInstance().findMenuById(this.menuId);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.fullMenuUI.setReady();
      }
    },
    async submitMenuSettings(): Promise<void> {
      this.fullMenuUI.clearError();

      try {
        await this.modalMenuSettings?.hide();
        await MenuRestService.getInstance().updateMenu(this.fullMenu.menu, this.fullMenu.id);

        await handleSavedSuccessfully();
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },

    openMenuSettings() {
      this.modalMenuSettings?.show();
    },

    clearExceptions(): void {
      this.fullMenuUI.clearError;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.fullMenuUI as UIStateDto))
        .catch(exceptions, true);
    },
    setIsReady(isReady: boolean): void {
      this.fullMenuUI.setIsReady(isReady);
    }
  },
});
